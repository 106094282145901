@use 'purecss/build/base.css';
@use './_partials/color-scheme';

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;

  @include color-scheme.default-to-dark-mode(
    $property-color-schemes: (
      (background-color, #333, #ccc),
    )
  );
}

.coox-hero {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  text-align: center;
  width: 100%;

  .coox-hero-title {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
      sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
    font-size: 20vw;
    line-height: 30vw;
    margin: 0;
    padding-bottom: 2vw;
    $transition: font-size 1s;
    transition: $transition;
    z-index: 1;

    @include color-scheme.default-to-dark-mode(
      $property-color-schemes: (
        (color, #ccc, #333),
      ),
      $extend-transition: (
        $transition,
      )
    );
  }
}

.coox-wave-strip-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%;

  .coox-wave-strip {
    border-bottom: 1vw solid;
    border-top: 1vw solid;
    height: 100%;
    $transition: height 1s;
    transition: $transition;
    width: 100%;

    @include color-scheme.default-to-dark-mode(
      $property-color-schemes: (
        (border-bottom-color, #999, #666),
        (border-top-color, #999, #666),
      ),
      $extend-transition: (
        $transition,
      )
    );
  }

  .coox-wave-strip-color-computer {
    height: 0;
    line-height: 0;
    margin: 0;
    padding: 0;
    width: 0;

    @include color-scheme.default-to-dark-mode(
      $property-color-schemes: (
        (background-color, #080808, #e8e8e8),
        (color, #666, #999),
      )
    );
  }
}
